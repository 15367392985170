<template>
  <a-spin :spinning="loading">
    <div class="payment-wrap wrapper" v-if="payment">
      <a-empty v-if="payment.length == 0" description="暂无缴费"></a-empty>
      <div v-else>
        <a-checkbox-group v-model:value="value" @change="getCheckedItem">
          <a-row>
            <a-col
              :span="24"
              v-for="(item, index) in payment"
              :key="index"
              style="margin-bottom: 10px"
            >
              <a-checkbox :value="item.payment_id">
                <div class="payment-item">
                  <div class="flex flex-between" style="align-items: baseline">
                    <div class="payment-top">
                      <div class="flex">
                        <div class="font-24 font-bold mr-20">
                          {{ item.payment_type_name }}
                        </div>
                        <div class="site-name font-14 text-gray">
                          {{ item.site_name }}
                        </div>
                      </div>
                      <div class="font-16">
                        {{ item.payment_start_time }}-{{
                          item.payment_end_time
                        }}
                      </div>
                    </div>
                    <div class="font-24 text-blue">
                      ¥{{ item.payment_value }}
                    </div>
                  </div>
                  <div
                    class="remark font-20 text-gray"
                    v-if="item.payment_remark"
                  >
                    {{ item.payment_remark }}
                  </div>
                </div>
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
        <div class="total">
          <span class="font-18">合计：</span>
          <span class="font-24 text-blue">¥{{ total }}</span>
        </div>
      </div>
      <div class="record-btn" @click="goToRecord">缴费<br />记录</div>
      <div class="btn" @click="insertPaymentOrderDataFun">确认缴费</div>
      <a-modal
        v-model:visible="visible"
        :closable="true"
        width="500px"
        :footer="null"
        :maskClosable="false"
        wrapClassName="payment-modal-box"
      >
        <div class="payment-modal-title">扫码支付</div>
        <div class="qrcode-wrap">
          <qrcode-vue :value="qrcodeText" size="300" level="H" />
        </div>
        <div class="handle-box flex">
          <div class="handle-btn" @click="updatePaymentOrderCancelDataFun">
            取消订单
          </div>
          <div
            class="handle-btn handle-btn-active"
            @click="updatePaymentOrderConfirmData"
          >
            我已支付
          </div>
        </div>
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import url from "@/utils/url.js";
import {
  getPaymentList,
  insertPaymentOrderData,
  updatePaymentOrderPayData,
  updatePaymentOrderCancelData
} from "@/api/main.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import QrcodeVue from "qrcode.vue";
export default defineComponent({
  name: "Payment",
  components: {
    QrcodeVue
  },
  setup() {
    const pageData = reactive({
      payment: [],
      loading: true,
      value: [],
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      total: 0,
      order_detail: [],
      qrcodeText: null,
      visible: false,
      order_id: null
    });
    const getPaymentListFun = () => {
      pageData.loading = true;
      getPaymentList({
        page: 1,
        limit: 100,
        user_id: pageData.user_id,
        park_id: pageData.userData?.user_park_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.payment = res.data.payment;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const countPrice = e => {
      let price = 0;
      pageData.payment.map(v => {
        for (let i = 0; i < e.length; i++) {
          if (v.payment_id == e[i]) {
            price += parseFloat(v.payment_value);
          }
        }
      });
      pageData.total = price;
    };
    const getCheckedItem = e => {
      countPrice(e);
      pageData.order_detail = e;

      //   console.log("pageData.order_detail", pageData.order_detail);
    };
    const updatePaymentOrderPayDataFun = order_id => {
      updatePaymentOrderPayData({
        user_id: pageData.user_id,
        order_id: order_id,
        trade_type: "NATIVE"
      })
        .then(res => {
          if (res.code == 200) {
            pageData.qrcodeText = res.data.wxpay.code_url;
            pageData.visible = true;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    };
    const insertPaymentOrderDataFun = () => {
      let order_detail = pageData.order_detail;
      if (order_detail.length == 0) {
        return message.error("请选择缴费项目");
      }
      pageData.order_detail = order_detail.map(v => {
        return {
          payment_id: v
        };
      });
      insertPaymentOrderData({
        user_id: pageData.user_id,
        order_detail: JSON.stringify(pageData.order_detail)
      })
        .then(res => {
          if (res.code == 200) {
            let order_id = res.data.order_id;
            pageData.order_id = order_id;
            updatePaymentOrderPayDataFun(order_id);
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    };
    //取消订单
    const updatePaymentOrderCancelDataFun = () => {
      updatePaymentOrderCancelData({
        user_id: pageData.user_id,
        order_id: pageData.order_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.qrcodeText = null;
            pageData.visible = false;
            pageData.order_id = null;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    };
    //我已支付订单
    const updatePaymentOrderConfirmData = () => {
      getPaymentListFun();
      pageData.qrcodeText = null;
      pageData.visible = false;
      pageData.order_id = null;
    };
    const goToRecord = () => {
      url.navigateTo("/payment/record");
    };
    getPaymentListFun();
    return {
      ...toRefs(pageData),
      getCheckedItem,
      insertPaymentOrderDataFun,
      updatePaymentOrderCancelDataFun,
      updatePaymentOrderConfirmData,
      goToRecord
    };
  }
});
</script>

<style lang="less">
.payment-wrap {
  padding: 50px 0;
  box-sizing: border-box;
  .btn {
    color: #fff;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    .ant-checkbox-inner {
      border-radius: 25px;
      width: 25px;
      height: 25px;
    }
    .ant-checkbox-inner::after {
      width: 8px;
      height: 12px;
    }
    .ant-checkbox-checked::after {
      border-radius: 25px;
    }
  }
  .payment-item {
    padding: 20px;
    box-shadow: 0px 0px 27px 0px #eeeeee;
    width: 1170px;
    // margin-bottom: 10px;
    border-radius: 10px;
    .payment-top {
      .site-name {
        padding: 0 10px;
        height: 23px;
        line-height: 23px;
        background-color: #f3f5f8;
        border-radius: 11px;
      }
    }
    .remark {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      background-color: #f3f5f8;
      margin-top: 20px;
    }
  }
  .total {
    text-align: right;
  }
  .record-btn {
    width: 80px;
    height: 80px;
    background-color: #1276cb;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    font-size: 18px;
    margin-left: auto;
    margin-top: 30px;
    cursor: pointer;
  }
}
.payment-modal-box {
  .ant-modal-content {
    width: 500px;
    height: 550px;
    background-image: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(18, 118, 203, 0.3) 100%
      ),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    border-radius: 20px;
  }
  .payment-modal-title {
    text-align: center;
    margin-top: 26px;
    font-size: 30px;
    font-weight: bold;
  }
  .qrcode-wrap {
    margin: 20px auto;
    display: flex;
    justify-content: center;
  }
  .handle-btn {
    width: 200px;
    height: 45px;
    line-height: 45px;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    color: #212531;
    font-size: 18px;
    border: 1px solid #999999;
    margin: 0 10px;
    margin-top: 20px;
  }
  .handle-btn-active {
    background-color: #1276cb;
    border-color: #1276cb;
    color: #fff;
  }
}
</style>
